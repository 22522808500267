import { OrgStore } from '@/stores/index';
import { Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

type Props = { price: number; showButtonsForFreeUsers?: boolean };

const ConsultantCoPay = ({ price, showButtonsForFreeUsers = false }: Props) => {
  const theme = useTheme();
  const { copayInfo } = OrgStore;

  return price && (copayInfo || showButtonsForFreeUsers) ? (
    <Typography
      fontSize={theme.spacing(3)}
      lineHeight="1.4 !important"
      variant="body2"
      color={theme.palette.grey[500]}
    >
      ${price} | Co-Pay
    </Typography>
  ) : null;
};

export default observer(ConsultantCoPay);
