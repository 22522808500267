import { Box, Stack, styled } from '@mui/material';
import NextImage from 'next/image';

export const CoachContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(3.5, 5, 4)};
  color: ${({ theme }) => theme.palette.grey[600]};
  cursor: pointer;
  height: 100%;
  position: relative;
`;
export const GuidanceVideoContainer = styled(Stack)<{ bgColor?: string }>`
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.palette.grey[600]};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const ModalWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  overflow: hidden;
  max-height: 98vh;
  width: 90vw;
  max-width: 50rem;

  @media screen and (min-width: 640px) {
    width: 80vw;
  }
`;

export const VideoModalWrapper = styled('div')`
  width: 90vw;
  @media screen and (min-width: 640px) {
    width: 75vw;
  }
  max-width: 60rem;
  background-color: ${({ theme }) => theme.palette.grey[700]};
  padding-top: 56%;
`;

export const BannerVideoImage = styled(NextImage)`
  opacity: 0.8;
  background-color: ${({ theme }) => theme.palette.grey[500]};
`;

export const learnCardMinWidth = 70;
export const LearnCard = styled(Stack)<{ darkBack?: boolean }>`
  flex: 1 1 ${({ theme }) => theme.spacing(learnCardMinWidth)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  min-height: ${({ theme }) => theme.spacing(56)};
  color: ${({ theme }) => theme.palette.grey[50]};
  position: relative;
  overflow: hidden;

  ${({ theme, darkBack }) =>
    darkBack
      ? `
        background: radial-gradient(
          51.97% 137.99% at 72.18% 100%,
          #def0f9 0%,
          #62aeba 67.71%,
          #5fa3a9 100%
        ),
        radial-gradient(
          51.97% 137.99% at 72.18% 100%,
          #fff7dc 0%,
          #ffc738 64.06%,
          #f2b235 100%
        );
      `
      : `background-color: ${theme.palette.primary[50]};`}
`;

export const LearnCardBackImage = styled(NextImage)`
  flex: 1;
`;

export const LearnCardDivider = styled('div')`
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  opacity: 0.5;
  width: 100%;
`;

export const LearnCardCommingSoon = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary[300]};
  font-size: ${({ theme }) => theme.spacing(4)};
  font-weight: 700;
  line-height: 1.5;
  padding: ${({ theme }) => theme.spacing(1.5, 4)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const InfoIconWrapper = styled('div')`
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  position: absolute;
  right: ${({ theme }) => theme.spacing(6)};
  bottom: ${({ theme }) => theme.spacing(5)};
`;

export const StyledAvatar = styled('div')<{ url: string }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: no-repeat center / contain url(${({ url }) => url});
`;
export const StyledVideo = styled('video')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  min-height: 100%;
  max-height: 100%;
`;
